export const content = {
    base_info:{
        verify_info:'请输入完整信息！',
        verify_phone:'请输入正确的手机号！',
        submit_info:'提交成功!',
        cance_info:'取消成功！',
    },
    menu: {
        create_title: '敬请期待',
        create_info: '正在建设中……',
        crosser: '穿越者',
        screen: '巨幕号',
        future: '未来号',
        company: '关于我们',
        fos: 'FOS介绍',
        ar: '穿越者',
        creator: 'future开发者联盟',
        meta: '未来元宇宙',
        about: '联系我们',
        consult: '立即咨询',
        zj: '只见未来',
        qpt: '未来全屏通',
        gywm: '关于我们',
        language: 'language',
        email: '服务邮箱',
        title: '未来全屏通智能科技有限公司',
        address: '湖北省武汉东湖新技术开发区雄庄路8号App广场2号楼10层',
        account: '账户'
    },
    home: {
        lb: ['轻装出行时尚，精彩跃然镜上', 'M.vision', '穿越者', '立即预约', '了解更多'],
        hxys: {
            title: '核心优势', list: [
                { title: '光学', info: '行业领先光学，精准定位，清晰呈现，让视觉更智能。' },
                { title: 'CR模式', info: '穿越现实(Crossing Reality)，通过合理分割用户的视野，实现虚拟世界和真实世界在同一空间中共存。' },
                { title: '整机', info: '高效交互，舒适佩戴，整机带来前所未有的便捷体验。' },
                { title: '团队', info: '深厚技术底蕴，创新思维引领，团队领航行业前沿。' },
                { title: '供应链', info: '整合全球优质资源，稳定高效，确保产品持续创新迭代。' },
                { title: '生态', info: '开放合作，共赢共生，激发无限创新可能，实现价值共创与共享。' }
            ]
        },
        download: {
            title: '穿越者APP',
            android_img: 'https://img.hbcygc.net/Files/App/future.apk',
            ios_img: 'https://apps.apple.com/cn/app/%E7%A9%BF%E8%B6%8A%E8%80%85cr/id6677037711'
        },
    },
    crosser: {
        topText: '人工智脑穿越未来',
        svg: 'Mvision.svg',
        list: [
            { title: '超轻薄形态，时尚与舒适兼备', info: '打造零压感佩戴体验，满足长时间使用。', info2: '重量仅' },
            { title: '抬头玩手机，减轻颈椎的负荷', info: '低头玩手机会造成颈椎压力，穿越号将手机内容投射在200寸屏幕上， 让你抬头仍能用手机，从而减轻了颈椎负荷。' },
            { title1: '小空间', title2: '也能装下大场面', info1: '虚拟成像，', info2: '使用不受空间大小限制。' },
            { title1: '开启CR模式', title2: '虚实同步呈现', info: '虚拟和现实同时展示，行走观影两不误' },
            { title1: '支持3D显示', title2: '带来全新的视觉冲击', info: '3D显示技术能够带来沉浸式的感官体验， 让你身临其境、全身心融入专属你的电影、游戏、故事等。' },
            { title1: '业内领先的“色彩专家”', title2: '用独一无二的色彩效果，定义您眼前的缤纷世界。', info: '踏上这场色彩的盛宴，打造属于您的视觉奇遇。' },
            { title1: '调节前', title2: '调节后' },
            { title: '防窥听防打扰 音频私密又动听', info: '持续输出清透平衡的动人音质' },
            { title1: '超高亮度', title2: '户外使用更清晰', info: '800nit超高显示亮度' },
            { title1: '适配主流设备', title2: '随时扩展大屏', info: '支持与外部设备的连接，如手机、游戏机等 进一步扩展了用户的使用场景和体验' },
            { title1: '携带无负担', title2: '满足多场景需求', info1: '外形小巧，携带无负担', info2: '满足更多场景的使用需求', info3: '出差旅行，说走就走' },
        ],
        list_img: ['https://img.hbcygc.net/website/v3/pc/crossers/list1.png', 'https://img.hbcygc.net/website/v2/crossers/list2.png', 'https://img.hbcygc.net/website/v3/pc/crossers/list3.png'],
        gd_list: [
            { title: '安全驾驶', info: '掌控路况信息，开启安全驾驶新征程' },
            { title: '巨幕观影', info: '畅享超大屏幕，细节尽收眼底！' },
            { title: '智慧办公', info: '办公随时随地，工作高效给力！' },
            { title: '趣体欢游', info: '真实互动，体验震撼快感！' }
        ],
    },
    fos: {
        tree_diagram: [
            { title: 'FOS从外观到核心的优势体现', url: 'https://img.hbcygc.net/website/v2/foses/ys.png' },
            { url: 'https://img.hbcygc.net/website/v2/foses/sj.png' },
            { title: 'FOS核心功能——3D', info: '云，端，显示，3D内容制作，3D直播，三维码标准', url: 'https://img.hbcygc.net/website/v2/foses/3d.png' },
            { title: 'FOS核心——全屏通', info: '通过FOS实现小屏变大屏', url: 'https://img.hbcygc.net/website/v2/foses/qpt.png' },
            { title: 'FOS核心——交互', info: '多种交互方式实现完美操作', url: 'https://img.hbcygc.net/website/v2/foses/jh.png' }
        ],
        list1: [
            { title: '外观', info: '利用眼镜的显示优势，呈现出震撼外观' },
            { title: '交互', info: '重中之重，交互体验最重要' },
            { title: '核心竞争力功能', info: '基于3D内容做后期处理算法' },
            { title: '生态', info: '接入三方应用和三方的视频内容，硬件控制设备的接入' }
        ],
        list2: {
            title: '模式',
            info: [
                ['虚拟不影响现实', '现实不影响虚拟'],
                ['让任何的光学眼镜都', '能够拥有沉浸式体验'],
                ['虚拟和现实分开', '让智能眼镜能长时间使用']
            ]
        },
        list3: {
            info: '是基于android系统Unity 3D 开发的一套在智能眼镜呈现的3D交互系统',
            list: ['个性化交互，可以支持键盘、鼠标、手柄', '体验流畅，操作丝滑，续航时间长', '隐私安全，权限透明']
        }
    },
    company: {
        list1: ['公司简介', '研究方向', '提供服务', '团队介绍'],
        text: ['未来全屏通智能科技集团有限公司', "成立于2021年，是一家通过国家高新技术企业认定，专注于元宇宙AR VR/MR等CR领域的创新型高科技企业。", "目前公司主要联合多个核心团队进行研发投入，包含中国科学院国家纳米科学中心、清华大学、华中科技大学、北京理工大学、湖南大学、 中科院博士等团队。针对于声、光、电三个大的方向进行核心涉及到语音领域、光学领域、芯片集成电路领域进行深入的设计、研发、生产及技术支持。 目前公司的科学家和工程师们始终运用第一性原理，对生物物理所涉及到的声、光、电、屏、端、网结合C端用户的体验生态进行了各类颠覆性的优化创新。 同时对硬、软件进行了低成本高效率的创新适配。最终使得该产品成为颠覆现有电子设备碎片化时间应用的模式，而成为全天侯服务于C端的集大成便携设备。"],
        list2: ['AI和大数据', '光学', '元宇宙', '芯片', '语音系统'],
        tg_img: '',
        tg_list: ['全方位的元宇宙，智能硬件服务', '强大的研发团队', '完善的系统集成', '庞大的产品整合能力', '自动化智造及丰富的，精密制造经验'],
        info: '未来全屏通主要核心团队进行研发投入，包含中科院、清华大学、华中科技大学、北京理工大学、湖南大学等团队针对于声、光、电三个大的方向进行核心涉及到针对智能眼镜硬件设备设计、研发、生产及技术支持',
        list3: [
            { name: '付金龙', type: '未来全屏通创始人', school: '华中科技大学' },
            { name: '邢硕', type: '未来全屏通创始人', school: '武汉信息传播大学' },
            { name: '蒋昌杰', type: '软件技术总监', school: '武汉理工大学' },
            { name: '王智远', type: '系统技术总监', school: '淡江大学' }
        ]
    },
    ar: {
        title: ['穿越者APP', '连接未来系列眼镜和Future OS的桥梁', '穿越者app是Futue OS的启动核心和用户管理和互动中心，以APP的形式展示。是启动FOS的一把钥匙。 除此之外，穿越者app还包含了3D空间，应用商店，商城。'],
        download: '立即下载',
        info: [
            { title: '应用商店', info: 'APP生态入口，提供所有元宇宙玩家的开发入口，支持U3D开发接口，提供给3D APP发烧友和专业3D开发团队将APP植入FOS 未来应用管理主要是用来管理需要在未来智镜中运行的应用。需要在只见未来上运行的APP，必须通过未来应用管理实现下载、安装、运行。' },
            { title: '3D空间', info: '内容生态入口，提供原宇宙视频内容发烧友将3D视频内容，全景视频内容，或者高清视频内容上传到FOS，自己享受自己的大屏和3D作品，史无前例。' },
            { title: '商城', info: '我们会为用户提供电子商城货架，以及虚拟商品和实体商品，让用户更加畅快的通过未来系列的眼镜获得更好的体验' }
        ]
    },
    creator: {
        title: '适用于 M.vision + Android 手机的Unity SDK,为Unity开发者提供 构建AR应用的工具',
        btns: ['SDK文档说明', '下载ARSDK'],
        floor: [
            { title: '提供以下能力', info: 'SDK 是基于Google CardBoard', list: ['双目立体渲染', 'M.vision头部追踪', '在线语音识别', '虚拟触控交互'] },
            { title: '使用 M.vision 屏幕的双目立体渲染能力，真实还原 3D 场景、物体的能力。', info: '基于 Google Cardboard SDK 与眼镜的适配' },
            { title1: '使用眼镜提供的 IMU', title2: '传感器的数据，', title3: '追踪使用者头部3Dof', title4: '运动姿态的能力。', info1: '基于眼镜的 IMU 传感器；', info2: '基于传感器数据的姿态算法。' },
            { title1: 'FOS提供的在线语音解决方案，', title2: '是为开发者和用户提供的语音交互方式。', info1: '支持中文指令词', info2: '基于语音识别库的识别能力', info3: '基于Android设备的麦克风' },
            { title: '为开发者和用户提供的触控交互方式。', info1: '戒指控制,连接戒指,通过虚拟射线进行控制', info2: '手机触摸控制,手机的 3Dof 数据转换为 3D 空间内的射线进行控制', info3: '提供 VRControllerInputModule提供事件、射线检测' },
        ]
    },
    meta: {
        title: '打造元宇宙核心目标',
        text: [
            {
                title: '我们',
                list:
                    [
                        '完成手机和眼镜连接的APP-未来中心，搭载FOS在眼镜中启动的 入口，以及周边生态的入口(应用入口、内容入口、消费入口）',
                        '完成FOS的元宇宙框架，外观的设计，基本3DUI的呈现，基本的 交互',
                        '对外SDK接口的提供，创造生态的基础平台'
                    ]
            },
            {
                title: '生态',
                list:
                    [
                        '应用场景：FOS提供给三方接入的接口，方便应用接入，同时FOS应用商城支持APP上传',
                        '交互方式：通过FOS提供给三方的手柄、键盘、手势识别等三 方达到多种交互方式'
                    ]
            }
        ],
        img: 'https://img.hbcygc.net/website/pc/scene/element105.png'
    },
    about: {
        name: '未来全屏通智能科技有限公司',
        list: ['联系方式：18908655001', '服务邮箱：service@fxreye.com', '地址：武汉东湖新技术开发区雄庄路8号光谷APP广场2号楼10层', '武汉东湖新技术开发区雄庄路8号光谷APP广场2号楼10层']
    },
    consult: {
        top: '立即咨询',
        title: '如果您想了解更多产品信息，请留下您的联系方式。',
        list: ['姓名', '电话', '留言', '提交', '未来全屏通智能科技有限公司', '联系方式', '服务邮箱']
    },
    login: {
        title: '触发想象，启动未来',
        text: ['请输入手机号', '请输入短信验证码', '发送验证码'],
        to_url: [' 我已阅读并同意', '《用户协议》', '和', '《隐私政策》'],
        btn_login: '登录',
    },
    my: {
        title: '个人中心',
        menu: ['基础信息', '预约信息', '编辑资料', '退出登录', '保存', '暂无预约','地址','取消预约'],
        base_info: ['用户名', '手机号', '生日', '公司名称', '职位名称'],
        order_status:['预约中','已处理','已取消'],
        page:['上一页','下一页'],
    },
    preorder: {
        top:'立即预约',
        title: ['M.visio 穿越号', '零售价：￥7500', '预留信息', '到货我们将第一时间联系您'],
        text: [
            { title: '手机号码', info: '请输入手机号' },
            { title: '姓名', info: '请输入姓名' },
            { title: '地址', info: '请输入地址' },
            { title: 'Color', info: '黑色' },
            { title: '选择数量' }
        ],
        save: '提交',
        msg: ['您已预约成功，是否跳转到个人中心', '确定']
    }
}