import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product',
    name: '产品',
    component: () => import('../views/product.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/support.vue')
  },
  {
    path: '/crosser',
    name: 'crosser',
    component: () => import('../views/crosser.vue')
  },
  {
    path: '/screen',
    name: 'screen',
    component: () => import('../views/screen.vue')
  },
  {
    path: '/future',
    name: 'future',
    component: () => import('../views/future.vue')
  },
  {
    path: '/fos',
    name: 'fos',
    component: () => import('../views/fos.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company.vue')
  },
  {
    path: '/ar',
    name: 'ar',
    component: () => import('../views/ar.vue')
  },
  {
    path: '/creator',
    name: 'creator',
    component: () => import('../views/creator.vue')
  },
  {
    path: '/meta',
    name: 'meta',
    component: () => import('../views/meta.vue')
  },
  {
    path: '/preorder',
    name: 'preorder',
    component: () => import('../views/preorder.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/privacy.vue')
  },
  {
    path: '/consult',
    name: 'consult',
    component: () => import('../views/consult.vue')
  },
  {
    path: '/sdkdocument',
    name: 'sdkdocument',
    component: () => import('../views/sdkdocument.vue')
  },
  {
    path: '/HardwareCode',
    name: 'HardwareCode',
    component: () => import('../views/HardwareCode.vue')
  },
  {
    path: '/enagreement',
    name: 'enagreement',
    component: () => import('../views/enagreement.vue')
  },
  {
    path: '/enprivacy',
    name: 'enprivacy',
    component: () => import('../views/enprivacy.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0};
}
})

// // 跳转后返回顶部
// router.afterEach((to,from,next) => {
//   window.scrollTo(0,0);
// })

export default router
