import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import BaiduMap from 'vue-baidu-map'
// import 'animate.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

import videojs from "video.js";
import "video.js/dist/video-js.css";

import { i18n } from './languages/index' 

import isMobile from './utils/isMobile'
Vue.prototype.$isMobile = isMobile

Vue.prototype.$video = videojs;

Vue.config.productionTip = false
window.$ = $

new Vue({
  router,
  store,
  i18n, //使用国际化
  render: h => h(App)
}).$mount('#app')

Vue.use(BaiduMap , {
    ak:"vOddFCAOb7aqPtq9aGKb7TGW9NGR39oe"
})

// // 全局挂载
// Vue.use(VueAwesomeSwiper)
